import axios from 'axios'
import jsonp from 'jsonp'
// import $ from 'jquery'
// const MODE = process.env.VUE_APP_MODE;
// const IsDev = process.env.NODE_ENV == 'development' ? true : false;
// let BASEURL = 'http://activityapi.insupai.com';

// if (MODE == 'test') {
//     BASEURL = 'http://activityapi.gongkongbpo.sta';
// }
// if (MODE == 'production') {
//     BASEURL = 'http://activityapi.insupai.com';
// }
// if (MODE == 'development') {
//     BASEURL = 'http://192.168.1.69:8002';
// }
import { Toast } from 'vant'
import { Promise } from 'core-js';
let loaddingToast = null;
// axios.defaults.baseURL = BASEURL;
axios.defaults.timeout = 30000;

axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';
// 添加请求拦截器
axios.interceptors.request.use(config => {
    return config;
});
// 添加响应拦截器   
axios.interceptors.response.use(response => {
    if (loaddingToast) {
        loaddingToast.clear();
    }
    return response.data;
}, error => {
    loaddingToast.clear();
    if (error.message.includes('timeout')) {
        Toast({
            message: '请求超时！',
            type: 'fail'
        });
    }
    return Promise.reject(error)
});

function request(method = 'post', url, params, loading = true) {
    if (loading) {
        loaddingToast = Toast.loading({
            duration: 0,
            message: '请稍候...',
            forbidClick: true
        });
    }
    // let baseUrl = BASEURL;
    // 上传接口的请求头改变成
    // if (url && url.indexOf('/api/Upload/UploadImg') != -1) {
    //     axios.defaults.headers['Content-Type'] = 'multipart/form-data';
    // } else {
    //     axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';
    // }
    //生产环境加baseURL
    // if (!IsDev && url.indexOf('http') != 0) {
    //     url = baseUrl + url
    // }
    // url = IsDev ? url : baseUrl + url;
    if (method == 'jsonp') {
        //jsonp请求
        return new Promise((resolve, reject) => {
            jsonp(url, null, (err, data) => {
                if (err) {
                    reject(err);
                    console.error(err.message);
                } else {
                    resolve(data);
                }
                if (loaddingToast) {
                    loaddingToast.clear();
                }
            })
        })
    }
    if (params) {
        params['platform'] = 'pcweb';
    } else {
        params = { platform: 'pcweb' }
    }
    return axios({
        url: url,
        method: method,
        data: method == 'post' ? params : null,
        params: method == 'get' ? params : null
    });
}

// function request(method = 'post', url, params, loading = true) {
//     if (loading) {
//         loaddingToast = Toast.loading({
//             duration: 0,
//             message: '请稍候...',
//             forbidClick: true
//         });
//     }
//     method = method.toUpperCase();
//     let _url = '';
//     //用户信息接口是主站的，不用加域名
//     if (!IsDev && url.indexOf('http') != 0) {
//         _url = BASEURL + url
//     } else {
//         _url = url;
//     }
//     if (method == 'POST' && params) {
//         params = JSON.stringify(params);
//     }
//     return new Promise(function(resolve, reject) {
//         $.ajax({
//             type: method,
//             url: _url,
//             dataType: 'json',
//             beforeSend: function(req) {
//                 if (_url.indexOf('/Login/UserLogin') !== -1) {
//                     //个人登录接口
//                     req.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=utf-8");
//                 } else {
//                     req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
//                 }
//             },
//             crossDomain: true == !(document.all),
//             // contentType: 'application/json;charset=UTF-8',
//             data: params,
//             success: function(data) {
//                 resolve(data);
//             },
//             error: function(err) {
//                 Toast({
//                     message: '请求超时！',
//                     type: 'fail'
//                 });
//                 reject(err);
//             },
//             complete: function() {
//                 if (loading) {
//                     loaddingToast.clear();
//                 }
//             }
//         });
//     })
// }

export default request;