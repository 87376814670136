import Vue from 'vue'
import Router from 'vue-router'
// import SiemensIndex from '@/pages/siemens/index.vue'
// import Siemens from '@/pages/siemens/siemens.vue'
// import SiemensSignIn from '@/pages/siemens/signIn.vue'
// import TrialClass from '@/pages/siemens/trialClass.vue'
Vue.use(Router);
export default new Router({
    // mode: 'history',
    routes: [{
        path: '/',
        // redirect: '/siemensIndex',
    }, {
        path: '/siemensIndex',
        Name: 'SiemensIndex',
        // component: SiemensIndex,
        component: resolve => require(['@/pages/siemens/index.vue'], resolve),
    }, {
        path: '/siemens',
        Name: 'Siemens',
        // component: Siemens,
        component: resolve => require(['@/pages/siemens/siemens.vue'], resolve),
    }, {
        path: '/siemensSignIn',
        Name: 'SiemensSignIn',
        // component: SiemensSignIn,
        component: resolve => require(['@/pages/siemens/signIn.vue'], resolve),
    }, {
        path: '/trialClass',
        Name: 'TrialClass',
        // component: TrialClass,
        component: resolve => require(['@/pages/siemens/trialClass.vue'], resolve),
    }, {
        path: '/prizeDetail',
        Name: 'PrizeDetail',
        component: resolve => require(['@/pages/inviteEngineer/prizeDetail.vue'], resolve),
    }, {
        path: '/inviteRegister',
        Name: 'InviteRegister',
        component: resolve => require(['@/pages/inviteEngineer/inviteRegister.vue'], resolve),
    }, {
        path: '/signDetail',
        Name: 'signDetail',
        component: resolve => require(['@/pages/signin/signDetail.vue'], resolve),
    }, {
        path: '/shareJob',
        Name: 'shareJob',
        component: resolve => require(['@/pages/shareJob/shareJob.vue'], resolve),
    }, {
        path: '/jobDetail',
        Name: 'jobDetail',
        component: resolve => require(['@/pages/shareJob/jobDetail.vue'], resolve),
    }, {
        path: '/kcl',
        Name: 'kcl',
        component: resolve => require(['@/pages/other/kcl.vue'], resolve),
    }]
})