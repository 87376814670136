import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import Vant from 'vant-ui'
import 'vant/lib/index.css'
import 'vant/lib/icon/local.css'
import '@/common/flexible.js'
import { ImagePreview, Lazyload, Dialog } from 'vant';
import request from '@/common/request.js'
import VConsole from 'vconsole'

if (process.env.VUE_APP_MODE === 'test') {
    let v_console = new VConsole();
}
Vue.use(Vant);
Vue.use(ImagePreview);
Vue.use(Lazyload);
Vue.use(Dialog);
Vue.config.productionTip = false
Vue.prototype.$http = request;
Vue.directive('title', {
    bind(el, binding) {
        document.title = binding.value;
    }
})
new Vue({
    render: h => h(App),
    router
}).$mount('#app')