<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  created() {
    //解决跳转到app时，webview不能识别path
    if(this.$route.path=='/' && window.location.search.indexOf('path=')!=-1){
      let query=location.search.substr(1);
      let arr=query.split('&');
      let obj={};
      for (let i = 0; i < arr.length; i++) {
        let _arr=arr[i].split('=');
        obj[_arr[0]]=_arr[1];
      }
      window.location.href=window.location.origin+'/#/'+obj.path;
    }
  },
}
</script>

<style>
@import url('~@/style/reset.scss');
</style>
